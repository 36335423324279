import { reactive } from 'vue';

export const researcherStore = reactive({
    user: window.nal.user,
    firm: window.nal.user && window.nal.user.firm,
    loggedIn() {
        return !!this.user;
    },
    finishedRegistration() {
        return this.user && typeof this.user.page === 'undefined';
    },
    setUser(user) {
        this.user = user;
    },
    setFirm(firm) {
        this.firm = firm;
    },
});
