<template>
    <div class="o-ezekia-nav">
        <div class="o-ezekia-nav__inner">
            <div class="o-ezekia-nav__left">
                <router-link
                    to="/home"
                    exact
                >
                    <div
                        class="o-ezekia-nav__logo"
                        style="background-image: url('/images/ezekia/Ezekia-logo-final2.png')"
                    >
                    </div>
                </router-link>
            </div>
            <form
                ref="logoutForm"
                id="logout-form"
                action="/logout"
                method="POST"
                style="display: none;"
            >
                <input
                    type="hidden"
                    name="_token"
                    :value="token"
                >
            </form>

            <div class="o-ezekia-nav__right o-ezekia-nav__large">
                <div class="o-ezekia-nav__links">

                    <div
                        v-if="!removeDistractions"
                        class="o-ezekia-nav__links"
                    >
                        <router-link
                            v-for="(link, index) in links"
                            :key="index"
                            class="o-ezekia-nav__link o-ezekia-nav__link--text u-pointer u-hover--grow"
                            :to="{ name: link.name }"
                        >
                            {{ trans('home-ezekia.nav.' + link.name) }}
                        </router-link>
                    </div>

                    <router-link
                        class="u-button-secondary u-hover--grow"
                        :to="{ name: 'demo' }"
                    >
                        {{ trans('home-ezekia.interested') }}
                    </router-link>

                    <div
                        class="u-button-cta u-hover--grow"
                        @click="enter"
                    >
                        {{ loggedIn ? trans('home-ezekia.nav.enter') : trans('home-ezekia.nav.signup') }}
                    </div>

                    <div
                        v-if="!removeDistractions"
                        class="o-ezekia-nav__links"
                    >
                        <router-link
                            v-if="!loggedIn"
                            class="o-ezekia-nav__link o-ezekia-nav__link--text u-pointer u-hover--grow"
                            :to="loginUrl"
                        >
                            {{ trans('home-ezekia.nav.login') }}
                        </router-link>
                        <a
                            v-else
                            class="o-ezekia-nav__link o-ezekia-nav__link--text u-pointer u-hover--grow"
                            href="/logout"
                        >
                            {{ trans('home-ezekia.nav.logout') }}
                        </a>
                    </div>

                </div>
            </div>

            <div
                v-blur="closeRespDropdown"
                class="o-ezekia-nav__resp o-ezekia-nav__small"
            >

                <div
                    class="o-ezekia-nav__burger u-hover--grow"
                    @click="respDropdown = !respDropdown"
                >
                    <i class="fal fa-bars"></i>
                </div>

                <div
                    v-show="respDropdown"
                    class="o-ezekia-nav__resp-dropdown"
                >
                    <div v-show="!respContent">

                        <div v-if="!removeDistractions">
                            <router-link
                                v-for="(link, index) in links"
                                :key="index"
                                :to="{ name: link.name }"
                                class="o-ezekia-nav__resp-line o-ezekia-nav__resp-main"
                                @click="respDropdown = false"
                            >
                                {{ trans('home-ezekia.nav.' + link.name) }}
                            </router-link>
                        </div>

                        <router-link
                            class="o-ezekia-nav__resp-line o-ezekia-nav__resp-main"
                            to="/demo"
                            @click="respDropdown = false"
                        >
                            {{ trans('home-ezekia.interested') }}
                        </router-link>

                        <a
                            class="o-ezekia-nav__resp-line o-ezekia-nav__resp-main"
                            @click="enter"
                        >
                            {{ loggedIn ? trans('home-ezekia.nav.enter') : trans('home-ezekia.nav.signup') }}
                        </a>

                        <div v-if="!removeDistractions">
                            <router-link
                                v-if="!loggedIn"
                                class="o-ezekia-nav__resp-line o-ezekia-nav__resp-main"
                                :to="loginUrl"
                                @click="respDropdown = false"
                            >
                                {{ trans('home-ezekia.nav.login') }}
                            </router-link>
                            <a
                                v-else
                                class="o-ezekia-nav__resp-line o-ezekia-nav__resp-main"
                                href="/logout"
                            >
                                {{ trans('home-ezekia.nav.logout') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { researcherStore } from './../../researcher/researcherStore.js';

const links = [
    {
        name: 'search-system',
    },
    {
        name: 'testimonials',
    },
];

export default {
    name: 'EzekiaNav',
    data() {
        return {
            respDropdown: false,
            respContent: false,
            loginUrl: { name: 'login' },
            signupUrl: { name: 'register' },
        };
    },
    computed: {
        removeDistractions() {
            return this.$route.name === 'landing';
        },
        loggedIn() {
            return researcherStore.loggedIn();
        },
    },
    methods: {
        closeRespDropdown() {
            this.respDropdown = false;
        },
        enter() {
            if (window.awaitingMandatory2FA) {
                return this.$router.push({ path: '/login/mandatory-2fa' });
            } else if (window.awaiting2FA) {
                return this.$router.push({ path: '/login/token' });
            } else if (window.browserNeedsVerification) {
                return this.$router.push({ path: '/login/new-browser-pin' });
            }

            if (!researcherStore.finishedRegistration()) {
                return this.$router.push(this.signupUrl).catch(_.noop);
            }

            window.location.href = '/';
        },
    },
    watch: {
        respDropdown(newValue) {
            if (!newValue) {
                this.respContent = false;
            }
        },
    },
    created() {
        this.links = links;
        this.token = window.token;
    },
};
</script>

<style lang="scss" scoped>

@import "@sass/mixins";
@import "@sass/variables";
@import "@sass/home-ezekia/variables";
@import "@sass/home-ezekia/utilities";

.o-ezekia-nav {
    background-color: #fff;
    color: $color__navy-400;
    font-size: 14px;
    width: 100%;

    &__inner {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 3px 30px;
    }

    &__left {
        align-items: center;
        display: flex;
        padding: 5px 0;
    }

    &__logo {
        background: no-repeat center center;
        background-size: contain;
        height: 50px;
        width: 160px;
    }

    &__right {
        align-items: center;
        display: flex;
    }

    &__current {
        font-size: 14px;
        font-weight: 400;
    }

    &__links {
        display: flex;
        margin-right: 10px;
    }

    &__link {
        align-self: center;
        padding: 10px 24px;
        position: relative;

        &--text {
            font-size: 14px;

            &:hover {
                background-color: $color__cobalt-100;
                border-radius: 6px;
                color: $color__cobalt-400;
            }
        }
    }

    &__small {
        display: none;
    }

    &__burger {
        font-size: 30px;
        line-height: 18px;
        padding: 14px 0;
    }

    &__resp {
        position: relative;

        &-dropdown {
            background-color: #fff;
            box-shadow: 0 4px 8px -1px rgba(0, 0, 0, .2);
            position: absolute;
            right: -20px;
            top: 47px;
            width: 220px;
            z-index: z("over");
        }

        &-main {
            border-left: 2px solid transparent;
            justify-content: space-between;
            padding: 18px 20px 14px 20px;
        }

        &-line {
            align-items: center;
            border-left: 2px solid transparent;
            display: flex;

            &:hover {
                background-color: $color__cobalt-100;
                color: $color__cobalt-400;
            }
        }
    }

    @media (max-width: 1000px) {
        &__left {
            padding: 0;
        }

        &__large {
            display: none;
        }

        &__small {
            display: block;
        }
    }

    @media (max-width: 920px) {
        &__resp-main {
            padding: 14px 20px 12px 20px;
        }
    }

    @media (max-width: 650px) {
        &__resp-main {
            padding: 12px 20px;
        }
    }

    @media (max-width: 350px) {
        &__resp-main {
            padding: 10px 20px;
        }

    }

}

.active {
    color: $color__cobalt-400;
    font-weight: 600;
}

.u-button-secondary {
    align-self: center;
    color: $color__navy-400;
    margin-left: 15px;
    padding: 10px 20px;

    &:hover {
    }
}

.u-button-cta {
    align-self: center;
    margin: 0 15px 0 25px;
    padding: 10px 20px;
}

</style>