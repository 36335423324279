import { createRouter, createWebHashHistory } from 'vue-router';

import { researcherStore } from './researcher/researcherStore.js';
import Analytics from '@js/core/cookies/analytics.js';

const Home = () => import('./vue-components/home/page-home.vue');
const Careers = () => import('./vue-components/careers/page-careers.vue');
const Register = () => import('./vue-components/registration/page-register.vue');
const Registration = () => import('./vue-components/registration/page-registration.vue');

const script = _.once(() => {
    var capterra_vkey = '11fe73456fcbc1850e4dd010d2ed1dd6',
        capterra_vid = '2149313',
        capterra_prefix = (('https:' === document.location.protocol)
            ? 'https://ct.capterra.com'
            : 'http://ct.capterra.com');
    var ct = document.createElement('script');
    ct.type = 'text/javascript';
    ct.async = true;
    ct.src = capterra_prefix + '/capterra_tracker.js?vid='
        + capterra_vid + '&vkey=' + capterra_vkey;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ct, s);
});

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/home',
            component: Home,
            alias: '/',
            name: 'home',
        },
        {
            path: '/landing',
            component: Home,
            name: 'landing',
            beforeEnter: (to, from, next) => {
                script();
                return next();
            },
        },
        {
            path: '/contact',
            component: () => import('./vue-components/contact/page-contact.vue'),
            name: 'contact',
        },
        {
            path: '/testimonials',
            component: () => import('./vue-components/news/page-testimonials.vue'),
            name: 'testimonials',
            alias: '/content',
        },
        {
            path: '/testimonials/:url',
            component: () => import('./vue-components/news/page-article.vue'),
            name: 'articles.show',
            props: true,
            alias: '/content/:url',
        },
        {
            path: '/search-system',
            component: () => import('./vue-components/search-system/page-system.vue'),
            name: 'search-system',
        },
        {
            path: '/demo',
            component: () => import('./vue-components/demo/page-demo.vue'),
            name: 'demo',
        },
        {
            path: '/terms/:tab?',
            component: () => import('./vue-components/terms/terms-wrapper.vue'),
            name: 'terms',
            props: true,
        },
        {
            path: '/login',
            component: Register,
            name: 'login',
            meta: {
                guest: true,
            },
            beforeEnter(to, from, next) {
                if (window.awaitingMandatory2FA) {
                    return next({
                        name: 'mandatory-2fa',
                    });
                }
                if (window.awaiting2FA) {
                    return next({
                        name: 'token',
                    });
                }
                if (window.browserNeedsVerification) {
                    return next({
                        name: 'new-browser-pin',
                    });
                }
                return next();
            },
        },
        {
            path: '/reset',
            component: Register,
            name: 'reset',
            meta: {
                guest: true,
            },
        },
        {
            path: '/invalid-password',
            component: Register,
            name: 'invalid',
            meta: {
                guest: true,
            },
        },
        {
            path: '/login/token',
            component: Register,
            name: 'token',
            beforeEnter(to, from, next) {
                if (!window.awaiting2FA) {
                    return next({
                        name: 'login',
                    });
                }
                return next();
            },
        },
        {
            path: '/login/new-browser-pin',
            component: Register,
            name: 'new-browser-pin',
            beforeEnter(to, from, next) {
                if (!window.browserNeedsVerification) {
                    return next({
                        name: 'login',
                    });
                }
                return next();
            },
        },
        {
            path: '/login/mandatory-2fa',
            component: Register,
            name: 'mandatory-2fa',
            beforeEnter(to, from, next) {
                if (!window.awaitingMandatory2FA) {
                    return next({
                        name: 'login',
                    });
                }
                return next();
            },
        },
        {
            path: '/register',
            component: Register,
            name: 'register',
            beforeEnter(to, from, next) {
                if (researcherStore.loggedIn()) {
                    return window.location.href = '/';
                }
                if (window.awaitingMandatory2FA) {
                    return next({
                        name: 'mandatory-2fa',
                    });
                } else if (window.awaiting2FA) {
                    return next({
                        name: 'token',
                    });
                } else if (window.browserNeedsVerification) {
                    return next({
                        name: 'new-browser-pin',
                    });
                }
                return next();
            },
            meta: {
                redirectsToCurrentPage: true,
            },
        },
        {
            path: '/register/basic-information',
            component: Registration,
            name: 'register.basic',
            meta: {
                requiresAuth: true,
                redirectsToCurrentPage: true,
            },
        },
        {
            path: '/register/pending',
            component: Registration,
            name: 'register.pending',
            meta: {
                requiresAuth: true,
                redirectsToCurrentPage: true,
            },
        },
        {
            path: '/register/agreement',
            component: Registration,
            name: 'register.agreement',
            meta: {
                requiresAuth: true,
                redirectsToCurrentPage: true,
            },
        },
        {
            path: '/register/pricing',
            component: Registration,
            name: 'register.billing',
            meta: {
                requiresAuth: true,
                redirectsToCurrentPage: true,
            },
        },
        {
            path: '/recover',
            component: Register,
            name: 'recover',
            props(route) {
                return route.query;
            },
        },
        {
            path: '/password/setup',
            component: Register,
            name: 'password.setup',
            props(route) {
                return route.query;
            },
        },
        {
            path: '/careers',
            component: Careers,
            name: 'careers',
        },
        {
            path: '/careers/fe1',
            component: Careers,
            name: 'careers.fe1',
            meta: {
                noScroll: true,
            },
        },
        {
            path: '/careers/fe2',
            component: Careers,
            name: 'careers.fe2',
            meta: {
                noScroll: true,
            },
        },
        {
            path: '/careers/be1',
            component: Careers,
            name: 'careers.be1',
            meta: {
                noScroll: true,
            },
        },
        {
            path: '/careers/be2',
            component: Careers,
            name: 'careers.be2',
            meta: {
                noScroll: true,
            },
        },
        {
            path: '/not-found',
            component: () => import('./vue-components/common/not-found.vue'),
            name: 'not-found',
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: to => {
                return {
                    path: '/login',
                    query: { redirect: to.fullPath },
                };
            },
        },
    ],
    linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.meta.noScroll) {
            return false;
        }
        return { left: 0, top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!researcherStore.loggedIn()) {
            return next({
                name: 'login',
                query: { redirect: to.fullPath },
            });
        }
    }
    if (to.matched.some(record => record.meta.guest)) {
        if (researcherStore.loggedIn()) {
            return next({
                name: 'register',
            });
        }
    }
    if (to.matched.some(record => record.meta.redirectsToCurrentPage)) {
        if (researcherStore.loggedIn() && !researcherStore.finishedRegistration()) {
            let currentPage = 'register.' + researcherStore.user.page;
            if (to.name !== currentPage) {
                return next({
                    name: currentPage,
                });
            }
        }
    }
    next();
});

Analytics.setPage({ path: router.currentRoute.path });

router.afterEach((to) => {
    Analytics.setPage({ path: to.path });
});

export default router;
