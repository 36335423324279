import { defineAsyncComponent } from 'vue';

import Vue3TouchEvents from 'vue3-touch-events';
import $ from 'jquery';

import '@sass/home-ezekia/app.scss';
import '../_app.js';
import './lang.js';
import router from './router.js';

import App from './vue-components/application.vue';

window.packageBuild = 'home-ezekia';

window.$ = $;
window.jQuery = $;
window.token = document.head.querySelector('meta[name="csrf-token"]').content;
window.mainSite = true;

$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    if (token) {
        jqXHR.setRequestHeader('X-CSRF-Token', window.token);
    }
    jqXHR.setRequestHeader('REQUEST_HASH', window.location.hash);
});

import('../vue3factory.js').then(({ createMyApp }) => {
    const homeEzekiaApp = createMyApp(App);

    // Register Uses
    homeEzekiaApp.use(Vue3TouchEvents);
    homeEzekiaApp.use(router);

    // Register Components
    homeEzekiaApp.component('PasswordErrorsAlert',
        defineAsyncComponent(() => import('@js/vue-components/auth/password-errors-alert.vue')));

    router.isReady().then(() => window.app = homeEzekiaApp.mount('#app'));
});